<template>
  <div>
    <h6>Data Realisasi</h6>
    <br />
    <div class="row justify-content-end">
      <v-text-field
        outlined
        dense
        prepend-inner-icon="mdi-magnify"
        placeholder="Cari..."
        style="max-width: 300px"
        v-model="optionsTable.search"
      />
    </div>
    <v-data-table
      :headers="headers"
      :items="data_realisasi.data"
      :options.sync="optionsTable"
      :search="optionsTable.search"
      group-by="seri"
      class="table-striped"
    >
      <template v-slot:[`item.kuota`]="{ item }">
        {{ item.kuota }} ({{ item.satuan }})
      </template>
      <template v-slot:[`item.kuota_terkecil`]="{ item }">
        {{ item.kuota_terkecil }} ({{ item.satuan_terkecil }})
      </template>
      <template v-slot:[`item.volume`]="{ item }">
        {{ item.volume }} ({{ item.satuan }})
      </template>
      <template v-slot:[`item.volume_terkecil`]="{ item }">
        {{ item.volume_terkecil }} ({{ item.satuan_terkecil }})
      </template>
      <template v-slot:[`item.nilai`]="{ item }">
        {{ item.nilai }} ({{ item.kurs_pib }})
      </template>
      <template v-slot:[`item.is_sent`]="{ item }">
        <v-chip
          :color="item.is_sent ? 'success' : 'error'"
          text-color="white"
          small
        >
          {{ item.is_sent ? "Terkirim" : "Belum" }}
        </v-chip>
      </template>
      <template v-slot:[`item.upload_id`]="{ item }">
        <a :href="linkDownload(item.upload_id)" target="_blank">
          <v-btn class="btn btn-outline-primary" small>
            <i class="fa-solid fa-download"></i>
          </v-btn>
        </a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "DataRealisasi",
  data() {
    return {
      headers: [
        { text: "Seri", value: "seri" },
        { text: "HS Code", value: "hs" },
        { text: "Uraian", value: "uraian" },
        { text: "Negara Asal", value: "negara_asal" },
        { text: "Pelabuhan Tujuan", value: "pelabuhan_tujuan" },
        {
          text: "Volume Sesuai Kuota (Dalam satuan Besar) /Satuan",
          value: "kuota",
        },
        {
          text: "Volume Sesuai Kuota (Dalam satuan Kecil) /Satuan",
          value: "kuota_terkecil",
        },
        {
          text: "Realisasi (dalam satuan besar) /Satuan",
          value: "volume",
        },
        {
          text: "Realisasi (dalam satuan kecil) /Satuan",
          value: "volume_terkecil",
        },
        { text: "Nilai Sesuai PIB /Kurs", value: "nilai" },
        { text: "File", value: "upload_id" },
        { text: "Status", value: "is_sent" },
      ],
      optionsTable: {
        page: 1,
        itemsPerPage: 5,
        search: "",
      },
      baseUrl: "https://api.batam-itinventory.com",
      linkDownload: (upload_id) => `${this.baseUrl}/documents/${upload_id}.pdf`,
    };
  },
  computed: {
    data_realisasi() {
      return this.$store.state.realisasi.data_realisasi;
    },
  },
  created() {
    this.$store.dispatch(
      "GetDataRealisasiByIdIjin",
      this.$route.params.id_ijin
    );
  },
};
</script>
